import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';


class NavBar extends Component {
    constructor(props){
        super(props);
        this.state = {
            projects: '200',
            about: '200',
            gallery: '200'
        };
    }

    updateLocation(page){
        if(page === 'pro'){
            this.state.projects = '400'
            this.state.about = '200'
            this.state.gallery = '200'
        }
        if(page === 'abo'){
            this.state.projects = '200'
            this.state.about = '400'
            this.state.gallery = '200'
        }
        if(page === 'gal'){
            this.state.projects = '200'
            this.state.about = '200'
            this.state.gallery = '400'
        }
    }
    

    render() {
        return (
            <header>
                
           
            <div id = "head-left" style={{transition: '.4s', boxShadow:'3px 3px 9px #00000000', position: 'fixed', zIndex:'1', top:'0px', background: '#fff', height:'100vh',width:'50px'}} >
                <div className = "hamburger" style = {{marginTop: "10px", marginLeft: "15px", transition: ".3s"}}>
                    <div class = "hamburgerLine" style = {{ height: "5px", width: "20px", borderBottom: "3px solid black"}}></div>
                    <div class = "hamburgerLine" style = {{ height: "5px", width: "20px", borderBottom: "3px solid black "}}></div>
                    <div class = "hamburgerLine" style = {{ height: "5px", width: "20px", borderBottom: "3px solid black "}}></div>
                    
                </div>
                <div className = "nameMine" style = {{positon: 'absolute', paddingTop: "20vh", transition: ".3s", marginLeft: '-127px'}}>
                    <p style = {{fontStyle: 'italic', positon: 'absolute', width: '300px',transform: 'rotate(-90deg)'}}>kathryn huff</p>   
                </div>
                <nav id = "nav">
                        <NavLink to ="/projects" id = "projects" onClick = {() => this.updateLocation('pro')} style = {{fontWeight : this.state.projects}}>projects</NavLink>
                        <NavLink to ="/gallery" id = "gallery" onClick = {() => this.updateLocation('gal')} style = {{fontWeight : this.state.gallery}}>gallery</NavLink>
                        <NavLink to ="/about-me" id = "about" onClick = {() => this.updateLocation('abo')} style = {{fontWeight : this.state.about}}>about </NavLink>
                        <a href = "mailto:kat@kathrynhuff.com"><i style={{fontSize: '15px', transition: '.4s',position: 'absolute', bottom: '30px', left: '17px'}}className="far fa-envelope-open"></i></a>
                        <a target="_blank" href = "https://www.artstation.com/katvile"><i style={{fontSize: '15px', transition: '.4s',position: 'absolute', bottom: '75px', left: '17px'}}className="fab fa-artstation"></i></a>
                </nav> 
            </div>
            <div id = "x"> <i className="fas fa-times" style = {{transition: '.3s', position: 'fixed', lineHeight: '1.9', color: 'black', top: '0', left:'-55px', zIndex:'3'}}></i></div>
           
        </header>
        );
    }
}

export default NavBar;