import React, { Component } from 'react';

class Contacts extends Component {
  render() {
    return (
      <div>
        <a href="mailto:wluns32@gmail.com">wluns32@gmail.com</a>
      </div>
    );
  }
}

export default Contacts;