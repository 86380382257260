import React, { Component } from 'react';
import './NotFound.css';

class NotFound extends Component {

  render() {
    return (
      <div style = {{width: '100%', textAlign: 'center', display:'flexbox'}} className="NotFound">
        <h1>404: NOT FOUND</h1>
        <h3 style = {{width: '100%', textAlign: 'center'}}>This address does not exist.</h3>
      </div>
    );
  }
}

export default NotFound;